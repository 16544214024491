define([
    'componentsCore',
    'wixFreemiumBanner/components/banner',
    'wixFreemiumBanner/components/banner2',
    'wixFreemiumBanner/components/coBrandingBanner'
], function (
    componentsCore,
    banner,
    banner2,
    coBrandingBanner
) {
    'use strict';

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.WixFreemiumBanner', banner);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.WixFreemiumBanner2', banner2);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.WixCoBrandingFreemiumBanner', coBrandingBanner);

    return {
        banner,
        banner2,
        coBrandingBanner
    };
});
